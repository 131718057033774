import React, { useEffect, useState } from "react";
import "./Finalize.css";
import { connect } from "react-redux";
import { Tab, Tabs } from 'react-bootstrap';
import PageLoader from '../../components/Loader/PageLoader';
import { finalizeAllocation, downloadExcel, downloadXml, resetAll, updateReserveQuantity, resetFinaliseData, downloadCsv, uploadFile, updateFinalizeInfo, disableEdit ,confirmDataAda,
  getPastAllocationPO, 
  downloadCsvBeforeFinalize} from './FinalizeAction'
import StoreView from './StoreView/StoreView'
import ProductView from './ProductView/ProductView'
import Notification from "../../components/Notification/Notifications";
import * as Notify from "../../components/Notification/Notifications";
import XML from 'xml-js'
import XLSX from 'xlsx'
import { saveFile } from '../../utils/fileSave'
import { isEmpty } from "lodash";
import { clientEnvId, hideFeatureForCurrentClient, isAshley, isCoach ,isTapestryJp} from "../../utils/commonUtilities"
import { CSVLink } from "react-csv";
import ExportFile from '../../components/ExportFile/ExportFile'
import axios from "axios";
import ConfirmationAlert from "../../components/ConfirmationAlert/ConfirmationAlert";
import { STRATEGY_PAGE } from "../../constants/routeConstants";
import { deleteDraft } from "../Strategy/actions";
import { ORDER_TYPE_OPTIONS } from "./constants";
import Select from "../../components/Filters/SingleSelect";
import SetAllModal from "../../components/ErrorModal/SetAllModal";
import { SingleDatePicker } from "react-dates";
import moment from "moment";
import ReactTollTip from "../../components/Table/Cellrenderer/ReactToolTip";


const Finalize = (props) => {
  let csvRef;
  const [activeTab, setActiveTab] = useState("fpv")
  const [planDetails, setPlanDetails] = useState(props.planDetails ? props.planDetails : props.history?.location?.state?.planDetails)
  const [viewAllocationCall, setViewAllocationCall] = useState(props.planDetails?.plan_id ? true : false)
  const [allocationCode, setAllocationCode] = useState(props.planDetails?.plan_id ? props.planDetails?.plan_id : (props.history?.location?.state?.planDetails?.allocationCode))
  const [allocationName, setAllocationName] = useState((props.allocationName ? props.allocationName : (props.history?.location?.state?.allocationName)))
  const [originalAllocationCode, setOriginalAllocationCode] = useState(props.planDetails?.plan_id ? props.planDetails?.plan_id: (props.history?.location?.state?.planDetails?.allocationCode))
  const [articles, setArticles] = useState([])
  const [downloadLoaderText, setDownloadLoaderText] = useState(null)
  const [finalized, setFinalized] = useState(false)
  const [retry, setRetry] = useState(false)
  const [tableInstance, setTableInstance] = useState(null)
  const [isPOASNFlow, setIsPOASNFlow] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)
  const [csvData, setCsvData] = useState([])
  const [csvHeader, setCsvHeader] = useState([])
  const [isFinalizeBtnClicked, setFinalizeBtn] = useState(false)
  const [downloadCsvfile, setdownloadCsvfile] = useState(false)
  const [isShowAlert, setIsShowAlert] = useState(false)
  const [showBack, setShowBack] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [orderType, setOrderType] = useState({});
  const [comments, setComments] = useState("");
  const [focusDate, setFocusDate] = useState(false);
  const [deliveryDate, setDeliveryDate] = useState();
  const [disableDate, setDisableDate] = useState(false);
  const [l1_name, setLevel1] = useState("");

  const handleTabSelect = (f_tab) => {
    if (!viewAllocationCall) {
      props.resetAll()
    }
    setActiveTab(f_tab)
  }

  useEffect(() => {
    if(props?.planDetails?.is_new_store) {
      setActiveTab("fpv")
    }
    if(viewAllocationCall && !props?.planDetails?.is_new_store) {
      props.getPastAllocationPO({ allocationcode: allocationCode, articles: planDetails?.articles})
    }
    if (props?.location?.state?.isPOASNFlow) {
      // setIsPOASNFlow(true)
      // setArticles(props?.location?.state?.articles)
    }
    let date = new Date();
    date.setDate(date.getDate() + 1);
    setDeliveryDate(moment(date));
  }, [])

  useEffect(() => {
    if(props.productData?.data?.length && isEmpty(orderType)) {
      const level1 = props?.productData?.data?.[0]?.l1_name;
      setLevel1(level1);
      //here in tapestry jp default no option will be selected
      // setOrderType(ORDER_TYPE_OPTIONS?.[l1_name][0]);
    }
  }, [props.productData])

  useEffect(() => {
    if(props.alloacationCode) {
      setIsUpdated(true)
      setAllocationCode(props.alloacationCode)
    }
  },[props.alloacationCode])

  useEffect(() => {
    setFinalized(false)
    setRetry(false)
    return () => {
      props.resetAll()
      props.resetFinaliseData()
    }
  }, [])

  const downloadXml = () => {
    props.downloadXml(props.finalizeData)
  }

  const downloadExcel = () => {
    props.downloadExcel(props.finalizeData)
  }

  const downloadCsv = () => {
    if ((activeTab === "fpv" && !(+props.productAllocationData?.[0]?.allocated_qty > 0)) || (activeTab === "fsv" && !(+props.storeAllocationData?.[0]?.allocated_units_total > 0))) {
      Notify.error("You can't download the plan with zero quantity allocated!!");
      return;
    }
    if (!finalized) {
      let req = {
        "allocationcode": allocationCode,
        "article": planDetails?.articles, 
      }
      props.downloadCsvBeforeFinalize(req)
    }
    else{
      props.downloadCsv(props.finalizeData)
    }
  }

  const finalizeAllocation = () => {
    props.confirmDataAda({
      "Allocation_Name": allocationName,
      "User_ID": Number(localStorage.getItem("user_id"))
    })
    setFinalizeBtn(true)
    // props.finalizeAllocation({
    //   allocation_code: originalAllocationCode, data: tableInstance?.data ? tableInstance?.data : tableInstance,
    //   retail_pro_id: localStorage?.getItem("retail_pro_id"),
    //   articles: props?.location?.state?.articles?.length ? props?.location?.state?.articles : planDetails?.articles
    // }, source)
  }

  useEffect(() => {
    const source = axios.CancelToken.source();

    if(isFinalizeBtnClicked) {
      if(props?.history?.location?.state?.planDetails?.draftId) {
        props.deleteDraft({ allocation_id: props?.history?.location?.state?.planDetails?.draftId, user_id: localStorage.getItem("user_id") })
      }
      props.finalizeAllocation({
        allocation_code: originalAllocationCode, data: tableInstance?.data ? tableInstance?.data : tableInstance,
        comments: comments, order_type: orderType?.value ? orderType?.value : "", delivery_dates: deliveryDate?.format("MM-DD-YYYY"),
        retail_pro_id: localStorage?.getItem("retail_pro_id"),
        articles: props?.location?.state?.articles?.length ? props?.location?.state?.articles : planDetails?.articles
      }, source)
    }

    return () => {
        if (source) {
          source.cancel("Landing Component got unmounted");
        }
    }
  }, [isFinalizeBtnClicked])

  const preFinalizeCheck = () => {
    setdownloadCsvfile(false)
    if((activeTab === "fpv" && !(+props.productAllocationData?.[0]?.allocated_qty > 0)) || (activeTab === "fsv" && !(+props.storeAllocationData?.[0]?.allocated_units_total > 0))){
      Notify.error("You can't finalize the plan with zero quantity allocated!!");
      return;
    }
    if(isUpdated) {
      props.disableEdit({
        allocation_code: allocationCode,
        original_allocation_code: originalAllocationCode
      })
    }
    else {
      finalizeAllocation();
    }
  } 

  const retryFileUpload = () => {
    props.uploadFile(props.finalizeData)
  }

  useEffect(() => {
    if(!props.allocationStatus) {
      if (!isEmpty(props.finalizeData?.PO) && !viewAllocationCall) {
        let req = {
          "allocation_code": originalAllocationCode,
          "user_id": localStorage.getItem("user_id"),
          "user_email": localStorage?.getItem("email"),
        }
        if (props?.location?.state?.isPOASNFlow) {
          req["article"] = props?.location?.state?.articles
        }
        else if(planDetails?.articles) {
          req["article"] = planDetails?.articles
        }
        if (isAshley() || isCoach() || isTapestryJp() ) {
          props.updateFinalizeInfo(req)
        }
        if (isAshley() || isCoach() || isTapestryJp) {
          props.uploadFile(props.finalizeData)
        }
        props.updateReserveQuantity(props.finalizeData)
      }
      else if(!isEmpty(props.finalizeData) && !viewAllocationCall) {
        setFinalizeBtn(false)
        Notify.error("Error in finalize data!!")
      }
    }
  }, [props.finalizeData])

  useEffect(() => {
    setCsvData(props.finalizeData?.Output ? props.finalizeData?.Output : props.finalizeData?.PO)
  },[props.finalizeData])

  useEffect(() => {
    if(!isEmpty(props.csvBeforeFinalizeData)) {
      setCsvData(props.csvBeforeFinalizeData)
      setdownloadCsvfile(true)
    }
  },[props.csvBeforeFinalizeData])

  useEffect(() => {
    !isEmpty(props.csvBeforeFinalizeHeader) && setCsvHeader(props.csvBeforeFinalizeHeader)
  },[props.csvBeforeFinalizeHeader])

  useEffect(() => {
    if(!isEmpty(props.downloadCsvData)) { 
      setCsvHeader(props.downloadCsvData)
      setdownloadCsvfile(true)
    }
  },[props.downloadCsvData])

  useEffect(() => {
    if (props.disableEditData && !viewAllocationCall){
      finalizeAllocation(originalAllocationCode)
    }
    if(props.disableEditError && !viewAllocationCall){
      setFinalizeBtn(false)
      Notify.error("Error in Finalizing the Alocation !!")
    }
  }, [props.disableEditData, props.disableEditError])

  useEffect(() => {
    if(!props.allocationStatus) {
      if (props.finalizeData?.status) {
        setFinalizeBtn(false)
        setFinalized(true)
        if(!viewAllocationCall) {
          setShowBack(false)
        }
        !viewAllocationCall && Notify.success("Allocation Finalized Successfully!!")
      }
      if (!props.finalizeData?.status && props?.finalizeData?.message) {
        setFinalizeBtn(false)
        viewAllocationCall ? Notify.error("You Cannot Download This Allocation!!") : Notify.error(props?.finalizeData?.message)
      }
    }
  }, [props.finalizeData, viewAllocationCall])

  useEffect(() => {
    if (props.uploadError) {
      setRetry(true)
      Notify.error("Failed to upload File, Please click on retry button!!")
    }
  }, [props.uploadError])

  useEffect(() => {
    if (props.uploadSuccess) {
      Notify.success("File Uploaded Successfully!!")
      setRetry(false)
    }
  }, [props.uploadSuccess])

  useEffect(() => {
    if (Object.keys(props.downloadXmlData).length) {
      var result = XML.json2xml(props.downloadXmlData, { compact: true });
      saveFile(result, `${planDetails?.allocationName ? planDetails?.allocationName : planDetails?.plan_description || planDetails?.allocationCode}.xml`)
    }
  }, [props.downloadXmlData])

  useEffect(() => {
    if (props.downloadExcelData.length) {
      const worksheet = XLSX.utils.json_to_sheet(props.downloadExcelData)
      const workbook = {
        Sheets: {
          "Allocation Plan": worksheet
        },
        SheetNames: ["Allocation Plan"]
      }
      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" })
      saveFile(excelBuffer, `${planDetails?.allocationName ? planDetails?.allocationName : planDetails?.plan_description || planDetails?.allocationCode}.xlsx`)
    }

  }, [props.downloadExcelData])

  useEffect(() => {
    if (!isEmpty(csvHeader) && !isEmpty(csvData) && downloadCsvfile) {
      setTimeout(() => {
        csvRef?.link?.click()
      }, 200)
    }
  }, [csvHeader, csvData])

  useEffect(() => {
    if(props.finalizeError) {
      //case was missing when allocation po api fails and if finalize button needs to be clicked again 
      setFinalizeBtn(false)
      Notify.error("Something Went Wrong")
    }
  },[props.finalizeError])

  useEffect(() => {
    if(props.csvBeforeFinalizeError){
      Notify.error("Something Went Wrong")
    }
  },[props.csvBeforeFinalizeError])

  const handleAlertOk = () => {
    preFinalizeCheck();

    setIsShowAlert(false);
  };

  const handleAlertCancel = () => {
    setIsShowAlert(false);
  };

  const handleAlertClose = () => {
    setIsShowAlert(false);
  };

  const goBackToCreateAllocation = () => {
    props.history.push(STRATEGY_PAGE, { isBackFromFinalize: true, allocationCode: props?.history?.location?.state?.planDetails?.draftId });
  }

  useEffect(() => {
    if(props.allocationStatus && !viewAllocationCall) {
      setShowBack(false);
      props.getPastAllocationPO({ allocationcode: allocationCode, articles: planDetails?.articles})
    }
    else if(viewAllocationCall || props?.history?.location?.state?.planDetails?.draftId) {
      setShowBack(true)
    }
  }, [viewAllocationCall ,props?.history?.location?.state?.planDetails?.draftId, props.allocationStatus])

  const isShowFinalizeButton = () => {
    return !props.allocationStatus && !finalized && !viewAllocationCall && (!props?.productDataError || !props?.storeDataError)
  }





  const toggleCommentsPopup = () => {
    setShowComments(!showComments)
  }

  const onOrderTypeChange = (val) => {
    setOrderType(val);
  }

  const handleCommentsChange = (text) => {
    setComments(text);
  }

  const prepFilter = React.useMemo(() => [
    {
      id: 1,
      isRequired: false,
      label: "Comments",
      name: "comments",
      className: "setAll__input",
      // value: comments,
      // onChange: (e) => {
      //   onUserDefinedInvSAChange(val)
      // },
      input: true,
      Comp: <textarea disabled={viewAllocationCall || finalized} value={comments} onChange={(e) => handleCommentsChange(e.target.value)} name="textarea" rows="5" cols="50" />,
    },
  ]);

  const handleSetAllOk = () => { 
    setShowComments(false);
  }

  const closeComments = (type) => {
    type && setComments("");
    setShowComments(false);
  }
  
  const onDateChange = (date) => {
    if(date) {
      //if date is null or empty then complete component gets removed from the screen. 
      setDeliveryDate(date)
    }
  }

  useEffect(() => {
    const details = props?.details?.[0]
    if(!isEmpty(details)) {
      setComments(details.comments || "-");
      setDeliveryDate(details.delivery_date_flag ? moment(new Date(details.delivery_date)) : null);
      setOrderType({label: details.order_type, value: details.order_type})
      setDisableDate(true);
    }
  }, [props.details])

  return (
    <PageLoader loader={props.finalizeLoading || props.uploadLoading || props.disableEditLoading || props.csvBeforeFinalizeLoading || 
      props.storeDataLoading || props.productDataLoading}>
      {/* {
        (!props.editAllocatedUnitError) &&
        <Notification />
      } */}
      {isShowAlert && (
        <ConfirmationAlert
          modalStyle={{ width: "45rem" }}
          message="You will not be able to modify the allocation plan post Finalize. Are you sure you want to continue ?"
          buttonLabel="Yes"
          handleOk={handleAlertOk}
          handleCancel={handleAlertCancel}
          handleClose={() => handleAlertClose()}
        />
      )}
      <div className="finalize__container">
        <div className="container__header">
          <h1 className="fnt-lg fnt-bold">{viewAllocationCall ? `Detailed View${props?.store ? ` (store - ${props?.store})` : ""}` : "Finalize"}</h1>
        </div>
        {
          <div className="row justify-content-center align-items-center mb-4 plan__details">
            <div className="mr-4">
              <span className="fnt-bold mr-2">Plan Name : </span>
              <span>{planDetails?.plan_description || planDetails?.allocationName || "-"}</span>
            </div>
            {
              planDetails?.creation_date &&
              <div className="mr-4">
                <span className="fnt-bold mr-2">Created on : </span>
                <span>{planDetails?.creation_date}</span>
              </div>
            }
            <span className="fnt-bold mr-2">Order Type</span>
            <div className="col-md-2 mb">
              <Select
                isDisabled={viewAllocationCall || finalized}
                name="level5"
                value={orderType}
                onChange={onOrderTypeChange}
                options={ORDER_TYPE_OPTIONS?.[l1_name]}
              />
            </div>
            {deliveryDate && <>
              <div style={{ position: 'relative', zIndex: 2 }}>
              <span className="fnt-bold mr-4">Delivery Date</span>
              <SingleDatePicker
                date={deliveryDate} // momentPropTypes.momentObj or null
                onDateChange={date => onDateChange(date)} // PropTypes.func.isRequired
                focused={focusDate} // PropTypes.bool
                onFocusChange={(focusedInput) => {
                  setFocusDate(focusedInput.focused);
                }}
                displayFormat="MM-DD-YYYY"
                disabled={disableDate}
                isOutsideRange={(day) => {
                  let formattedDate = moment(day).format(),
                    isSameOrAfter = moment(formattedDate).isSameOrAfter(new Date());
                  return !isSameOrAfter;
                }}
                withPortal={true}
                portalId="date-picker-portal"
              />
              </div>
              </>}
            {!(finalized || props.allocationStatus || viewAllocationCall) ? 
            <button className="btn btn-primary comments_btn" onClick={toggleCommentsPopup}>Comments</button>
            : <span className="d-flex comments_text">            
                <span className="fnt-bold mr-2">Comments:</span>
                <span><ReactTollTip sliceLength={30} value={comments} id={"finalize_comments"} /> </span>
              </span>
            }
          </div>
        }
        {/* modalBodyStyle={{ height: '45rem' }} */}
        {showComments && 
          <SetAllModal 
            filter={prepFilter} 
            handleOk={handleSetAllOk} 
            handleCancel={() => closeComments(1)} 
            handleClose={closeComments} 
          />
        }

        <div className="container__body finalize__tabs__body">
          {
            showBack &&
            <button className="btn btn-primary back__btn" onClick={() => { 
              viewAllocationCall ? props.goBack(false) : goBackToCreateAllocation()
              }}>
              {"<< Back"}
            </button>
          }
          <div className="container__tabs">
            <Tabs defaultActiveKey="fpv" activeKey={activeTab}
              onSelect={handleTabSelect}>
              <Tab eventKey="fpv" title="Product View">
                {
                  (activeTab === "fpv" || viewAllocationCall) &&
                  <ProductView activeTab={activeTab} allocationCode={allocationCode} releasedDate={planDetails?.released_date}
                  viewAllocationCall={viewAllocationCall} isFinalized={finalized || props.allocationStatus}
                  originalAllocationCode={originalAllocationCode}
                  setTableInstance={setTableInstance} tableInstance={tableInstance}
                  isPOASNFlow={planDetails?.isPOASNFlow} articles={planDetails?.articles}
                  is_new_store={planDetails?.is_new_store ? planDetails?.plan_id : false} />
                }
              </Tab>
              {!planDetails?.is_new_store &&
                <Tab eventKey="fsv" title="Store View">
                  {
                    (activeTab === "fsv" || viewAllocationCall) &&
                    <StoreView activeTab={activeTab} allocationCode={allocationCode} releasedDate={planDetails?.released_date}
                      rowArticles={planDetails?.articles}
                      originalAllocationCode={originalAllocationCode}
                      viewAllocationCall={viewAllocationCall} isFinalized={finalized || props.allocationStatus}
                      setTableInstance={setTableInstance} tableInstance={tableInstance}
                      isPOASNFlow={planDetails?.isPOASNFlow} isPOASN={planDetails?.isPOASN}
                      articles={planDetails?.articles} is_new_store={planDetails?.is_new_store} />
                  }
                </Tab>
            }
            </Tabs>
          </div>
          {
            <div className="finalize__btn text-center">
              {/* {!viewAllocationCall && 
                <button className="btn undo-constraints-mode" style={{ margin: '2rem', top: 0 }} onClick={goBackToCreateAllocation}>
                  Back
                </button>
              } */}
              {
                isShowFinalizeButton() &&
                <button className="btn btn-primary" style={{ margin: '2rem' }} onClick={() => setIsShowAlert(true)}>
                  Finalize
                </button>
              }
              {
                finalized && retry && !viewAllocationCall &&
                <button className="btn btn-primary" style={{ margin: '2rem' }} onClick={retryFileUpload}>
                  Retry
                </button>
              }
              {
                // finalized &&
                <>
                  {
                    !hideFeatureForCurrentClient("finalize_excel") &&
                    <>
                      <button className="btn btn-primary mr-4 px-2" onClick={downloadExcel}>
                        <i
                          className="fa fa-download mr-2"
                          title="Configure"
                          aria-hidden="true"
                        ></i>
                        Download Allocation in Excel
                      </button>
                      <button className="btn btn-primary px-2" onClick={downloadXml}>
                        <i
                          className="fa fa-download mr-2"
                          title="Configure"
                          aria-hidden="true"
                        ></i>
                        Download Allocation in XML
                      </button>
                    </>
                  }
                  {
                    !hideFeatureForCurrentClient("finalize_csv") && 
                    <>
                      <CSVLink data={!isEmpty(csvData) ? csvData : ""} headers={!isEmpty(csvHeader) ? csvHeader : ""} filename={`${planDetails?.allocationName || planDetails?.plan_description || planDetails?.allocationCode}.csv`}
                        ref={(r) => csvRef = r}>
                      </CSVLink>
                      <ExportFile text={"Download Allocation in CSV"} callFunc={downloadCsv} type={3} />
                    </>
                  }
                </>
              }
            </div>
          }
        </div>
      </div>
    </PageLoader>
  )
}


const mapStateToProps = ({ finalize }) => {
  return {
    editAllocatedUnitError: finalize.editAllocatedUnitError,
    dcAvailableError: finalize.dcAvailableError,
    finalizeLoading: finalize.finalizeLoading,
    finalizeError: finalize.finalizeError,
    finalizeData: finalize.finalizeData,
    downloadXmlData: finalize.downloadXmlData,
    downloadExcelData: finalize.downloadExcelData,
    downloadCsvData: finalize.downloadCsvData,
    uploadError: finalize.uploadError,
    uploadLoading: finalize.uploadLoading,
    uploadSuccess: finalize.uploadSuccess,
    productDataError: finalize.productDataError,
    storeDataError: finalize.storeDataError,
    alloacationCode: finalize.alloacationCode,
    disableEditLoading: finalize.disableEditLoading,
    disableEditData: finalize.disableEditData,
    disableEditError: finalize.disableEditError,
    productAllocationData: finalize.productAllocationData,
    storeAllocationData: finalize.storeAllocationData,
    csvBeforeFinalizeData: finalize.csvBeforeFinalizeData,
    csvBeforeFinalizeLoading: finalize.csvBeforeFinalizeLoading,
    csvBeforeFinalizeError: finalize.csvBeforeFinalizeError,
    csvBeforeFinalizeHeader: finalize.csvBeforeFinalizeHeader,
    storeDataLoading: finalize.storeDataLoading,
    productDataLoading: finalize.productDataLoading,
    allocationStatus: finalize.allocationStatus,
    productData: finalize.productData,
    details: finalize.details
  }
};

const mapDispatchToProps = (dispatch) => ({
  finalizeAllocation: (payload, source) => dispatch(finalizeAllocation(payload, source)),
  confirmDataAda: (payload) => dispatch(confirmDataAda(payload)),
  updateReserveQuantity: (payload) => dispatch(updateReserveQuantity(payload)),
  downloadXml: (payload) => dispatch(downloadXml(payload)),
  downloadExcel: (payload) => dispatch(downloadExcel(payload)),
  downloadCsv: (payload) => dispatch(downloadCsv(payload)),
  downloadCsvBeforeFinalize: (payload) => dispatch(downloadCsvBeforeFinalize(payload)),
  resetFinaliseData: () => dispatch(resetFinaliseData()),
  uploadFile: (payload) => dispatch(uploadFile(payload)),
  updateFinalizeInfo: (payload) => dispatch(updateFinalizeInfo(payload)),
  resetAll: () => dispatch(resetAll()),
  getPastAllocationPO: (payload) => dispatch(getPastAllocationPO(payload)),
  disableEdit: (payload) => dispatch(disableEdit(payload)),
  deleteDraft: (payload) => dispatch(deleteDraft(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(Finalize);